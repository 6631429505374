import React, { useEffect, useState } from 'react';
import Styled from 'styled-components';
import { Button, Form, Input } from 'semantic-ui-react';
import API from './API';

const Container = Styled.div`
	background-color: #008B97;
	width: 100vw;
	height: 100vh;
	color: white;
`;
const Centered = Styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate( -50%, -50% );
	min-width: 300px;
	text-align: center;
`;

interface PageState {
	siteName: string,
	password: string,
}

export default function Login() {
	const [ state, setState ] = useState<PageState>({
		siteName: "",
		password: "",
	});

	const doLogin = () => {
		API.authenticate(state.password)
		.then( token => {
			localStorage.setItem("token:site-docs", token);
			window.location.reload();
		})
		.catch( e => {
			console.log(e);
		});
	}

	useEffect( ()=>{
		API.fetchConfig()
		.then(config => {
			setState(s => ({
				...s,
				siteName: config.name
			}));
		})
	}, []);

	return (
		<Container>
			<Centered>
				<h1>{state.siteName}</h1>
				{state.siteName} is protected with a password,
				<br/>
				please type the password in and press Login
				<br/>
				<Form style={{
					marginTop: "20px",
					textAlign: "left",
				}}>
					<Form.Field>
						<label style={{
							color: "white"
						}}>Password</label>
						<Input
							fluid
							placeholder="Type the password here..."
							type="password"
							value={state.password}
							onChange={ e => setState(s => ({
								...s,
								password: e.target.value,
							}))}
						/>
					</Form.Field>
					<div style={{ textAlign: "right" }}>
						<Button content="Login" secondary onClick={doLogin}/>
					</div>
				</Form>
			</Centered>
		</Container>
	);
}