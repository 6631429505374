import Config from './interfaces/Config';

class API {
	async fetchConfig(): Promise<Config> {
		const req = await fetch("/api/v1/config");
		if (!req.ok) {
			throw new Error(await req.text());
		}
		return req.json();
	}
	async authenticate(password: string): Promise<string> {
		const req = await fetch("/api/v1/auth", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				password
			}),
		});
		const response = await req.json();
		if (!req.ok) {
			throw new Error(response.message);
		}
		return response.token;
	}
	async checkToken(token: string): Promise<boolean> {
		console.log("Checking Token");
		const req = await fetch("/api/v1/auth", {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		if (!req.ok) {
			return false;
		}
		return true;
	}
}
export default new API();