import React, { useEffect, useState } from 'react';
import { DirectoryEntry } from './interfaces/DirectoryEntry';
import { Button, Icon, Loader, Popup, Table } from 'semantic-ui-react';
import Styled from 'styled-components';
import API from './API';

const PageContainer = Styled.div`
	background-color: #333333;
	min-height: 100vh;
`;
const Header = Styled.div`
	background-color: #008B97;
	color: white;
	font-size: 14pt;
	display: flex;
	a {
		color: white;
		font-weight: bold;
	}
	.text {
		padding: 15px;
		display: inline-block;
	}
	.path {
		padding: 15px;
		display: inline-block;
		background-color: #006e78;
		flex: 1;
	}
	.buttons {
		text-align: center;
		padding: 6px;
	}
`;

const Content = Styled.div`
	tr {
		cursor: pointer;
	}
`;

interface PageState {
	name: string,
	loading: boolean,
	contents: DirectoryEntry[],
}

function App() {
	const [ state, setState ] = useState<PageState>({
		name: "",
		loading: true,
		contents: [],
	});

	useEffect(() => {
		document.title = `Viewing ${window.location.pathname}`;
		loadContents();
		API.fetchConfig()
		.then(config => {
			setState(s => ({
				...s,
				name: config.name,
			}));
			document.title = `${config.name} - Viewing ${window.location.pathname}`;
		})
	}, []);

	const loadContents = async () => {
		const fr = await fetch(`/api/v1/list${window.location.pathname}`);
		if (!fr) {
			alert("Error fetching files!");
			return;
		}
		const response = await fr.json();
		setState(s => ({
			...s,
			loading: false,
			contents: response as DirectoryEntry[],
		}));
	}

	const openDirectory = (path: string) => {
		window.location.pathname = path;
	}
	const generateBreadcrumb = () => {
		let path = decodeURI(window.location.pathname);
		if (path === "") {
			path = "/";
		}

		const explodedPath = path.split("/");
		let homeLink = `/ <a href="/">Home</a>`;
		const breadcrumbElements = explodedPath.map( (p, i) => {
			if (i === 0) {
				return `<a href="${p}">${p}</a>`;
			} else {
				const fullPath = explodedPath.slice(0, i);
				return `<a href="${fullPath.join("/")}">${p}</a>`;
			}
		});
		let breadcrumb = "";
		if (breadcrumbElements.length > 0) {
			breadcrumb = breadcrumbElements.join(" / ");
		}
		return `${homeLink}${breadcrumb}`;
	}
	const downloadFile = async (path: string) => {
		const file = await fetch(`/api/v1/download/${path}`);
		if (!file.ok) {
			alert(await file.text());
			return;
		}
		const js: { url: string } = await file.json();
		window.open(js.url);
	}
	const logout = () => {
		localStorage.removeItem("token:site-docs");
		window.location.reload();
	}

	if (state.loading) {
		return <PageContainer>
			<Loader inverted active>
				Loading files..
			</Loader>
		</PageContainer>;
	}

	return (
		<PageContainer>
			<Header>
				<span className="text">{state.name}</span>
				<span className="path" dangerouslySetInnerHTML={{ __html: generateBreadcrumb() }} />
				<span className="buttons">
					<Button icon="logout" onClick={logout} content="Logout" labelPosition="left" secondary/>
				</span>
			</Header>
			<Content>
				<Table inverted selectable={(state.contents.length !== 0)} attached>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={1} textAlign="center"></Table.HeaderCell>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell>Size</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{state.contents.map( entry => {
							if (entry.type === 'file') {
								return (
									<Table.Row onClick={() => downloadFile(entry.fullPath)}>
										<Table.Cell textAlign="center"><Icon size="large" color="blue" name="file outline"/></Table.Cell>
										<Table.Cell>{entry.name}</Table.Cell>
										<Table.Cell width={2}>File</Table.Cell>
										<Table.Cell width={2}>{entry.size}</Table.Cell>
									</Table.Row>
								);
							} else {
								return (
									<Table.Row onClick={() => openDirectory(entry.fullPath + "/")}>
										<Table.Cell textAlign="center" width={1}><Icon size="large" color="yellow" name="folder"/></Table.Cell>
										<Table.Cell>{entry.name}</Table.Cell>
										<Table.Cell width={2}>File folder</Table.Cell>
										<Table.Cell width={2}></Table.Cell>
									</Table.Row>
								);
							}

						})}
						{state.contents.length <= 0 && <Table.Row>
							<Table.Cell colSpan={5}>
								<div style={{
									textAlign: "center",
									fontStyle: "italic"
								}}>
									There are no files to show
								</div>
							</Table.Cell>
						</Table.Row>}
					</Table.Body>
				</Table>
			</Content>
		</PageContainer>
	);
}

export default App;
