import React, { useEffect, useState } from 'react';
import Login from './Login';
import Browse from './Browse';
import { Loader } from 'semantic-ui-react';
import API from './API';

interface PageState {
	loading: boolean,
	requireLogin: boolean,
}

export default function App() {

	const [ state, setState ] = useState<PageState>({
		loading: true,
		requireLogin: true,
	});

	useEffect(() => {
		const token = localStorage.getItem("token:site-docs");
		if( token ) {
			console.log("Found token")
			consumeToken(token);
		} else {
			console.log("no token")
			setState( s => ({
				...s,
				loading: false,
			}));
		}
	}, []);

	const consumeToken = async(token: string) => {
		console.log("Consuming token");
		const r = await API.checkToken(token);
		if (r) {
			console.log("Token is valid")
			setState( s => ({
				...s,
				loading: false,
				requireLogin: false,
			}));
		} else {
			localStorage.removeItem("token:site-docs");
			setState( s => ({
				...s,
				loading: false,
			}))
		}
	}

	if (state.loading) {
		return <Loader active>Please wait... loading.</Loader>;
	}
	if (state.requireLogin) {
		return <Login />;
	}
	return <Browse />;
}